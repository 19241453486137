body{
    overflow-y: auto !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.scroll_page .e-movablecontent {
    overflow-x: scroll !important;
}

#blip-chat-container #blip-chat-open-iframe {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    position: fixed;
    width: 4%;
    max-width: 60px;
    min-width: 48px;
    max-height: 60px;
    min-height: 48px;
    right: 30px !important;
    bottom: 60px;
    transition: transform 200ms, opacity 500ms, visibility 500ms;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 2px 32px rgba(0, 0, 0, 0.1);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

#blip-chat-container #blip-chat-iframe {
    position: fixed;
    display: flex;
    top: 90px;
    right: 38px !important;
    opacity: 0;
    transition: opacity 500ms, transform 500ms, visibility 500ms, height 0s 500ms;
    transform: translateY(10%);
    z-index: 1;
    height: 0;
    width: 400px;

    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: #faf9f8;
}

.shine-version {
    color:rgb(0, 0, 0);
    font-size: 1e;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bolder;
    -webkit-mask-image: linear-gradient(-75deg, rgba(255,255,255,.1) 10%, rgb(255, 255, 255) 50%, rgba(255, 252, 255, 0.856) 100%);
    -webkit-mask-size: 200%;
    animation: shine 2s linear infinite;
}

@keyframes shine {
    from { -webkit-mask-position: 150%; }
    to { -webkit-mask-position: -50%; }
}

#move-chat{
    display: none;
    width: 22px;
    height: 22px;
    position: absolute;
    margin: 10px;
    bottom: -20px;
    cursor: move;
    border-radius: 100%;
    background-color: #F76556;
    color: #fff;
    text-align: center;
    font-size: 16px;
}

#blip-chat-open-iframe:hover #move-chat, #move-chat:active{
    display: block;
}

#blip-chat-notifications{
    top: 17px;
    left: 47px;
}

#user-data .subnavdropdown .dropdown-toggle{
    display: block;
    width: 100%;
    padding-left: 25px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.subnavdropdown .nav-link{
    padding: 4px 1rem;;
}

.btn-change{
    height: 40px;
    margin-right: 20px;
    border-radius: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10020;
    margin-bottom: 5px;
}

.btn-change-dark-mode{
    width: 200px;
    height: 40px;
    border-radius: 10px;
}

.bg-theme{
    background-color: #c37c44;
}
.bg-cream{
    background-color: #C6D2D9;
}
.text-black{
    color: #212529 !important;
}
.accordion_header_desembolso .accordion-button{
    background-color: #c37c44;
    color: #fff;
}
.fw-bold{
    font-weight: bold;
}


/* Links */
.link {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
}
.link:hover {
    text-decoration: none;
}

.link::before,
.link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.link::before {
    content: '';
    /* show by default */
}

/* Links 1 */
.link-1::before {
    height: 2px;
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

.link-1:hover::before {
    height: 2px;
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}
