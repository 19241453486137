.nd-container {
    padding: .5rem .5rem 1rem .5rem;
    text-align: left;
}

.nb-sem-notificacoes {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 8rem;
    text-align: center;
}

.nb-notificacao-card a {
    text-decoration: none;
    color: #212529;
}

.nb-notificacao-card {
    padding: 0.5rem 1rem;
    text-align: left;
    border-radius: 3px;
    transition: all 0.25s linear;
}

.nb-notificacao-card:hover {
    background-color: #f8f9fa;
}

.nd-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nd-title {
    color: #c37c44;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0;
}
.nd-ver-todas{
    color:#49A95A
}
.nd-ver-todas:hover{
    color:#49A95A
}

.nd-ver-todas .material-icons{
    font-size: 12px;
}

.nd-br {
    width: 100%;
    margin: 0.5rem 0px;
}

.nb-tipo-info {
    color: #C37C44;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: .4rem;
}

.nb-mensagem {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -5px;
}

.nb-tempo {
    display: inline;
    color: #8E8E8E;
    font-size: 12px;
    font-weight: 400;
}

.nb-badge {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: red;
    margin-left: .5rem;
}

.dropdown-toggle::after {
    display: none;
}

#nsd-notificacao + .dropdown-menu {
    min-width: 400px;
    left: -194px;
    margin-top: 21px;
    max-height: 438px;
    overflow-y: auto;
}

.dropdown-menu.show + #nsd-notificacao:before {
    content: "";
    position: absolute;
    border-style: solid;
    height: 0;
    width: 0;
    border-color: #fff transparent transparent;
    border-width: 20px 10px 0;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    z-index: 999;
    transform: rotate(179deg);
    transition: border-color .2s ease,bottom .2s ease;
    top: -20px;
    left: 212px;
}

.nb-loader_container {
    height: 10rem;
}

.nb-loader_inicial {
    position: relative;
    top: calc(50% - 1.5em);
    left: calc(50% - 1.5em);
    width: 3em;
    height: 3em;
    border-color: rgba(1, 64, 9, 0.808) rgba(4, 110, 18, 0.753) rgba(5, 171, 27, 0.35) rgba(26, 199, 49, 0.5) !important;
    box-sizing: border-box;
    border: 0.6em solid;
    border-radius: 50%;
    animation: load_nb 1.1s infinite linear;
    transition: opacity 0.3s;
    z-index: 90000;
}

@keyframes load_nb {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



