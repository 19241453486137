
.flag img{
    display: inline-block;
    width: 38px;
    height: auto;
    padding:2px;
    cursor: pointer;
}

.flag img:hover{
    transform: scale(1.2);
}

.position-idioma {
    position: fixed;
    bottom: 0;
    left: 10px;
    width: 130px;
    z-index: 10020;
}
