@import '../../sass/base/filter.scss' ;

.e-toolbar {
    -webkit-tap-highlight-color: transparent;
    background: #fff;
    border: 0;
    box-shadow: none;
}

.e-menu-wrapper {
    border: none;
    border-radius: 0;
    width: 100% !important;
    padding: 0;
    display: block !important;
}

.e-menu-wrapper .e-menu-item {
    cursor: pointer;
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 50px;
    line-height: 40px;
    padding: 0 10px;
    vertical-align: top;
    white-space: nowrap;
}

/** Avatar customization */
.e-menu-wrapper .e-menu-item .e-avatar {
    background-color: transparent;
    font-size: 8px;
    align-self: center;
    width: auto;
    overflow: visible;
}

/** Card customization */
.e-menu-wrapper .e-menu-item .e-card {
    font-size: inherit;
    background-color: inherit;
    border-color: transparent;
}

.e-menu-wrapper .e-menu-item .e-card .e-card-content {
    white-space: normal;
    color: inherit;
    text-align: center;
    font-size: inherit;
    padding: 0;
    margin: 0;
    border: 0;
}

.e-menu-wrapper .e-menu-item .e-card .e-card-header {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: normal;
    min-height: 22.5px;
    padding: 0;
    width: inherit;
}

.e-menu-wrapper .e-menu-item.e-focused {
    background-color: #949090;
    outline-color: transparent;
    border-radius: 4px;
}

.e-menu-wrapper .e-menu-item.e-selected {
    background-color: #9b9ba0;
    outline-color: transparent;
    border-radius: 4px;
}

.e-menu-wrapper .e-selected-selected {
    background-color: #bdbdc2;
    outline-color: transparent;
    border-radius: 4px;
}

#content-darshboard:has(#menu-lancamento){
    padding: 0px;
}

#menu-lancamento {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    padding: 0 !important;
    background: #433942;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.25s linear;
}

#menu-lancamento .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    padding: 11px 20px;
    border-radius: 0;
    transition: all 0.25s linear;
}

#menu-lancamento .card svg{
    margin: auto;
}

#menu-lancamento .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0;
}

#menu-lancamento .card-title {
    margin: 0 !important;
    font-size: 14px;
    font-weight: normal;
    color: #fff !important;
}

#menu-lancamento .navbar-brand {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.bg-selected {
    background-color: #2c7231 !important;
}

.bg-not-selected {
    background-color: transparent !important;
}

#menu-lancamento .card:hover {
    background-color: #2c7231 !important;
    transition: all ease .5s;
}

.navbar-brand-menu {
    text-decoration: none;
}

#left-menus {
    flex-grow: 1;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 60px;
}

.notificacao-div {
    display: inline;
    margin-right: 1rem;
}

.notificacao-icone {
    height: 25px;
    width: 25px;
}

.notificacao-badge {
    position: absolute;
    bottom: 24px;
    width: 22px;
    height: 22px;
    margin-left: 15px;
    border-radius: 50px;
    background-color: #c37c44;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    line-height: 20px;
}
.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: .5;
}

.btn-info-notification{
    background-color: #49a95a;
    color: #fff;
    border: 1px solid #49A95A;
    border-radius: 3px;
    transition: all ease .3s;
}

.btn-info-notification:hover{
    background-color: #c37c44;
    color: #fff;
    border: 1px solid #c37c44;
    transition: all ease .3s;
}
.btn-info-notification .material-icons{
    font-size: 18px;
}
.e-contextmenu-wrapper ul{
    width: 310px !important;
}