#user-data {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;

    .dropdown-toggle {
        color: #fff;
    }
    a {
        text-decoration: none;
        padding: 0;
    }
    span {
        color: #fff;
        font-size: 13px;
    }

    .dropdown-menu {
        position: absolute;
        margin-top: 40px;
        margin-right: 50px;
        left: unset;
    }

    .dropdown-menu a {
        width: 270px;
        padding: 0.5rem 1rem;
        text-align: left;
        border-radius: 3px;
        transition: all 0.25s linear;
        display: flex;
    }
    .dropdown-menu a .material-icons{
        font-size: 22px;
        color: #c37c44;
        padding-right: 5px;
    }
    .dropdown-menu p{
        margin: 0px;
    }
    .dropdown-menu:before {
        content: "";
        position: absolute;
        border-style: solid;
        height: 0;
        width: 0;
        border-color: #fff transparent transparent;
        border-width: 20px 10px 0;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        z-index: 999;
        transform: rotate(179deg);
        transition: border-color .2s ease,bottom .2s ease;
        top: -20px;
        left: 212px;
    }

    //.dropdown-toggle{
    //    padding-right: 15px;
    //}
    //.dropdown-toggle:before{
    //    content: "";
    //    position: absolute;
    //    border-style: solid;
    //    height: 0;
    //    width: 0;
    //    border-color: #fff transparent transparent;
    //    border-width: 10px 5px 0;
    //    border-left-color: transparent !important;
    //    border-right-color: transparent !important;
    //    z-index: 999;
    //    transform: rotate(0deg);
    //    transition: border-color .2s ease,bottom .2s ease;
    //    top: 9px;
    //    left: 87px;
    //}
    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
}

.dropdown-menu {
    position: absolute;
    right: -50px;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .6rem 1rem;
    margin-top: 2rem;
    font-size: 1rem;
    color: #212529;
    text-align: center;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}
.modal{
    z-index: 99999 !important;
}
