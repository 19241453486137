
.e-certo_confirma:after{
    content:'\e259';
    font-size: 2em;
    font-weight: bold;
  }
  
  .e-incorreto_cancela:after{
    content:'\e22a';
    font-size: 1em;
    font-weight: bold;
  }  
  
  .style-button-sim-dialog{
    background-color: green !important;
  } 
  
  .style-button-nao-dialog{
    background-color: red !important;
  }  