#container-spinner{
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease;
  z-index: 90000;
}

.loader_inicial {
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 4em);
  z-index: 90000;
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader_inicial div {
  transform-origin: 40px 40px;
  animation: loader_inicial 1.2s linear infinite;
}
.loader_inicial div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 7px;
  height: 19px;
  border-radius: 21%;
  background: #f5faf6
}
.loader_inicial div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.loader_inicial div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.loader_inicial div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.loader_inicial div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.loader_inicial div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.loader_inicial div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.loader_inicial div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.loader_inicial div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.loader_inicial div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.loader_inicial div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.loader_inicial div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.loader_inicial div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes loader_inicial {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}