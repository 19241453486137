body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#container-spinner{
  background: #0000004f;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90000;
}

html, body{
    background-color: whitesmoke;
    transition: all 0.25s linear;
}

.e-headertext{
  color: #c37c44;
}

.bg-green{
  background: #77c384
}

.bg-yellow{
  background: #ffc46c;
}

.bg-red{
  background-color:#9e4c4c;
}

.bg-blue{
  background: #6aa5ca;
}
