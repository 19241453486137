
/*#list-referencia .list-group-item {
	position: relative;
	display: block;
	padding: 0;
	background-color: #fff;
    border: none;
    margin: 0;
    line-height: 15px;
}

#list-referencia .list-text-label{
    font-weight: bold;
    font-size: 12px;
    padding: 0;
}

.list-referencia-position {
    position: absolute;
    top: 1px;
    right: 0px;
    width: '100px';
    z-index: 1000;
}*/

#list-referencia .breadcrumb {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 3px;
	margin-bottom: 0;	
	list-style: none;
	background-color: #e9ecef;
	border-radius: 0;
}

#list-referencia .breadcrumb > li + li:before {
    color: #c37c44;
    content: "//";
}

#list-referencia .breadcrumb-item.active{
	color: #5d3f23 !important;
	font-size: 12px;
}